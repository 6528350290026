exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archivo-js": () => import("./../../../src/pages/archivo.js" /* webpackChunkName: "component---src-pages-archivo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manifesto-js": () => import("./../../../src/pages/manifesto.js" /* webpackChunkName: "component---src-pages-manifesto-js" */),
  "component---src-templates-articulo-js": () => import("./../../../src/templates/Articulo.js" /* webpackChunkName: "component---src-templates-articulo-js" */),
  "component---src-templates-categoria-js": () => import("./../../../src/templates/Categoria.js" /* webpackChunkName: "component---src-templates-categoria-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/Tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

